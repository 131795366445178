import { render, staticRenderFns } from "./OssUpload.vue?vue&type=template&id=43393688&"
import script from "./OssUpload.vue?vue&type=script&lang=js&"
export * from "./OssUpload.vue?vue&type=script&lang=js&"
import style0 from "./OssUpload.vue?vue&type=style&index=0&id=43393688&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/.jenkins/workspace/platform_prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43393688')) {
      api.createRecord('43393688', component.options)
    } else {
      api.reload('43393688', component.options)
    }
    module.hot.accept("./OssUpload.vue?vue&type=template&id=43393688&", function () {
      api.rerender('43393688', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/OssUpload.vue"
export default component.exports